<template>
	<div class="index">
		<z-header :curActive="0"></z-header>
		<!-- 勐海普洱茶介绍 -->
		<div style="width: 100%;background-color:#f8f1e9;">
			<div class="typeArea">
				<div class="content-Title">
					<span>勐海普洱茶介绍</span>
					<span>MENGHAI PU'ER TEA INTRODUCTION</span>
				</div>
				<div class="content-text">
					<p v-for="(item,index) of indexData" :key="index">{{item.text}}</p>
				</div>
				<div class="content-img" v-if="screenState">
					<img src="../imgs/index/c1.png">
					<img src="../imgs/index/c2.png">
				</div>
				<div v-else @click="toIndexInfo">
					<img   style="width: 125px; height: 33px;margin-top:30px;" src="../imgs/index/a2.png" alt="查看更多">
				</div>
			</div>
		</div>
		<!-- 勐海产品 -->
		<div class="typeArea">
			<div class="content-Title">
				<span>勐海产品</span>
				<span>MENGHAI PRODUCTS</span>
			</div>
			<div class="chanp">
				<div @click="toBrand(1)">
					<div>
						<img  v-if="!screenState" src="../imgs/index/d1app.png">
						<span>传统普洱茶</span>
						<p>也称为生茶，是指云南大叶种茶树鲜叶经杀青、揉捻、晒干等工序，再整理或蒸压成形后，由环境影响自然产生发酵演变而成的茶类产品。</p>
					</div>
				</div>
				<div  @click="toBrand(2)">
					<div>
						<img  v-if="!screenState" src="../imgs/index/d2app.png">
						<span>现代普洱茶</span>
						<p>是指1974年创制出的以云南大叶种晒青毛茶为原料，经人工增湿、渥堆后发酵等工序加工而成的普洱茶产品。</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 文化历史 -->
		<div class="history" style="height:1100px;">
			<div class="typeArea">
				<div class="content-Title">
					<span  style="color: #fff">文化历史</span>
					<span  style="color: #fff">CULTURAL HISTORY</span>
				</div>
				<div class="history-img">
					<div>
						<span>普洱茶属于再加工特种茶，是以云南大叶种晒青毛茶为原料，经后发酵等工艺加工而成，是具有悠久历史和厚重文化底蕴的传统名茶，是云南独特的地理标志产品。由于有云南大叶种茶独特的品种特性、优良的生态环境及特殊的生产工艺，成就了普洱茶独特的品质和丰富的保健功效，成为历经千百年而不衰的茶类产品，在国内外享有盛誉。</span>
					</div>
					<div v-if="!screenState">
						<img src="../imgs/index/e1app.png" alt="茶马古道">
						<img src="../imgs/index/e2app.png" alt="茶庄文化">
					</div>
					<div>
						<span>在1700多年的发展历程中，随着社会生产力的不断进步，普洱茶的生产利用方法也不断改进和完善，产品形式多样，有各种散茶及团茶、饼茶、瓜茶、紧茶、砖茶、沱茶、竹简茶等各种紧压茶，成为中国乃至世界上最为丰富多彩的一类茶叶产品。因此，为了正确认识普洱茶，可将其分为传统普洱茶和现代普洱茶两大类。</span>
					</div>
				</div>
			</div>
		</div>
		<!-- 产地工艺 -->
		<div class="craft">
			<div class="typeArea">
				<div class="content-Title">
					<span>产地工艺</span>
					<span>ORIGIN TECHNOLOGY</span>
				</div>
				<div class="craft-content">
					<div>
						<div>
							<div @click="toCraft(1)">
								<img v-if="screenState" src="../imgs/index/f1.png">
								<img v-else src="../imgs/index/f1app.png">
								<div>
									<span v-if="screenState">种植系统</span>
									<p>构建普洱茶品种作物生长模型，对普洱茶的种植信息进行多维度管理，实现普洱茶种植的智能化、精准化、数字化辅助管理以及种植全过程的留痕、追溯及监测。</p>
									<p v-if="screenState">了解更多 ></p>
								</div>
							</div>
						</div>
						<div>
							<div @click="toCraft(2)">
								<img v-if="screenState"  src="../imgs/index/f2.png">
								<img v-else src="../imgs/index/f2app.png">
								<div>
									<span v-if="screenState">品质溯源</span>
									<p>搭建统一的农产品质量安全追溯体系和平台，建立健全普洱茶种植规范标准，通过二维码标签追溯产品基本信息，全面提升勐海县农产品质量安全的智慧监管能力。</p>
									<p v-if="screenState">了解更多 ></p>
								</div>
							</div>
						</div>
						<div>
							<div @click="toCraft(3)">
								<img v-if="screenState"  src="../imgs/index/f3.png">
								<img v-else src="../imgs/index/f3app.png">
								<div>
									<span v-if="screenState">制茶技艺</span>
									<p>历史的浸润和自身的后发酵使得普洱茶的陈韵内敛在茶中。要发挥普洱茶之真韵，必须有科学的冲泡方法和一颗宁静的心，要心怀赞美感恩的情愫才能感受茶的纯真。</p>
									<p v-if="screenState">了解更多 ></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 新闻资讯 -->
		<div class="typeArea">
			<div class="content-Title">
				<span>新闻资讯</span>
				<span>NEWS</span>
			</div>
			<div class="news">
				<div v-for="(item,index) of newsData.slice(0,1)" :key="index" class="news-item" style="cursor: pointer;" @click="toNewsInfo(item.id,item.categoryId)">
					<div class="news-img">
						<div class="img">
							<img  v-if="item.coverImage != null && item.coverImage != undefined && item.coverImage != '' && item.coverImage !='null' && item.coverImage != 'undefined'" :src="urlConfig + item.coverImage">
							<img v-else src="../imgs/news/1.jpg">
						</div>
						<div>
							<div class="news-time">
								<span>{{item.createTime.substring(8,10)}}</span>
								<span>{{item.createTime.substring(0,7)}}</span>
							</div>
						</div>
					</div>
					<span>{{item.title}}</span>
					<p>{{item.description}}</p>
				</div>
				<div>
					<ul>
						<li v-for="(item,index) of newsData.slice(1,5)" :key="index"  class="news-item"  style="cursor: pointer;" @click="toNewsInfo(item.id,item.categoryId)">
							<div class="news-time">
								<span>{{item.createTime.substring(8,10)}}</span>
								<span>{{item.createTime.substring(0,7)}}</span>
							</div>
							<div>
								<span>{{item.title}}</span>
								<p>{{item.description}}</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<router-link to="/news/newsList" class="toNews"></router-link>
		</div>
	</div>
</template>
<script>
	import {getRecommend} from '@/api/api.js'
	import indexData from './data'
	import zHeader from "../components/header.vue"
	import apiConfig from "../api/config"
	export default{
		components:{zHeader},
		data(){
			return{
				indexData : [],
				src:'',
				newsData:[],
				urlConfig:'',
				imgHover:true,
				screenState:true
			}
		},
		created(){
			// 初始化获取浏览器窗口大小
			let screenWidth = document.body.clientWidth;
			// 根据浏览器窗口大小判断是否显示页面内的部分内容
			if(screenWidth<1200){
				this.screenState=false;
			}else{
				this.screenState=true;
			}

			this.getNewsList()
			this.indexData = indexData.index
			this.urlConfig = apiConfig.url
		},
		methods:{
			// 获取新闻列表
			getNewsList(){
				let params = {
					pageNum : 1,
					pageSize : 5
				}
				getRecommend(params,(res) => {
					this.newsData = res.data
				})
			},
			// 跳转到工艺界面
			toCraft(index){
				this.$router.push({path:"/craft?id="+index})
			},
			// 跳转新闻详情页
			toNewsInfo(id,categoryId){
				let query = {
					id : id,
					type : categoryId
				}
				this.$router.push({path:'/news/newsInfo',query:query})
			},
			// 跳转勐海产品页面
			toBrand(id){
				let query = {
					id:id
				}
				this.$router.push({path:'/Brand',query:query})
			},
			// 跳转普洱茶介绍详情页
			toIndexInfo(){
				this.$router.push({path:'/indexInfo'})
			}
		}
	}
</script>

<style scoped="scoped">
    .index .typeArea{
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	p{
		cursor: pointer;
	}
	/* 文化历史 */
	.history{
		background-image: url('../imgs/index/bg1.png');
		background-repeat: no-repeat;
		background-size: 100% 500px;
		background-color: #f7f7f7;
	}
	.craft{
		background-image: url('../imgs/index/bg2.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.content-Title{
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top: 100px;
	}
	.content-Title > span:first-child{
		font-size: 36px;
		font-weight: bold;
		letter-spacing: 4px;
		color: #867d70;
	}
	.content-Title > span:last-child{
		font-size: 15px;
		line-height: 61px;
		letter-spacing: 2px;
		color: #999999;
	}
	.content-text{
		font-size: 15px;
		line-height: 61px;
		color: #444444;
		line-height: 28px;
	}
	.content-text p{
		margin-top: 40px;
	}
	.content-img{
		width: 100%;
		margin: 40px 0 100px 0;
		position: relative;
	}
	.content-img > img:last-child{
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 9;
	}
	.chanp{
		width: 100%;
		margin: 60px 0 120px 0;
		display: flex;
		justify-content: space-between;
	}
	.chanp > div{
		width: 580px;
		height: 310px;
		position: relative;
		cursor: pointer;
	}
	.chanp > div:first-child{
		background-image: url("../imgs/index/d1.png");
		background-repeat: no-repeat;
	}
	.chanp > div:last-child{
		background-image: url("../imgs/index/d2.png");
		background-repeat: no-repeat;
	}
	.chanp>div>div{
		position: absolute;
		width: 205px;
		height: 100%;
		right: 0;
		padding: 10px 25px;
		box-sizing: border-box;
	}
	.chanp  span{
		display: inline-block;
		font-size: 16px;
		line-height: 50px;
		letter-spacing: 0px;
		color: #bfa377;
		border-bottom: 2px solid #bfa377;
	}
	.chanp p{
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #444444;
		margin-top: 30px;
		line-height: 28px;
	}
	.history-img{
		background-image: url("../imgs/index/e1.png");
		width: 100%;
		height: 780px;
		margin-top: 12px;
		position: relative;
	}
	.history-img > div{
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.history-img > div:first-child{
		top: 0;
		right: 0;
		width: 650px;
		height: 280px;
	}
	.history-img >div:last-child{
		left: 0;
		bottom: 0;
		width: 700px;
		height: 250px;
	}
	.history-img span{
		font-size: 15px;
		color: #444444;
		line-height: 28px;
	}
	.history-img span:first-child{
		width: 541px;
		top: 69px;
		right: 60px;
	}
	.history-img span:last-child{
		width: 554px;
		left: 39px;
		bottom: 75px;
	}
	.craft-content{
		width: 100%;
	}
	.craft-content>div{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 30px 0 100px 0;
		width: 100%;
	}
	.craft-content>div>div{
		width: 375px;
		height: 310px;
		overflow: hidden;
	}
	.craft-content>div>div>div{
		width: 100%;
		height: 200%;
		cursor: pointer;
		transition: all 0.5s linear;
	}
	.craft-content>div>div>div:hover{
		transform: translateY(-50%);
	}
	.craft-content img{
		width: 100%;
		height: 50%;
		display: block;
	}
	.craft-content>div>div>div>div{
		width: 100%;
		height: 50%;
		background-color: #867d70;
		box-shadow: 0px 0px 10px 0px 
			rgba(0, 0, 0, 0.3);
		border-radius: 4px;
		padding: 50px 45px 40px 40px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: #fff;
		font-size: 14px;
		line-height: 28px;
	}
	.craft-content>div>div>div>div>span{
		font-size: 20px;
		letter-spacing: 10px;
	}
	.craft-content>div>div>div>div>a{
		color: #ffffff;
	}
	.news > div{
		width: 50%;
		margin-top: 35px;
	}
	.news-img{
		width: 100%;
		height: 380px;
		position: relative;
		border-radius: 4px;
	}
	.news-img .img{
		width: 100%;
		height: 100%;
		overflow: hidden;
		left: 0;
	}
	.news-img .img  img{
		width: 100%;
	}
	.news-img > div{
		position: absolute;
		top: 0;
		left: 30px;
		background-color: #d6ab7ece;
		border-radius: 0px 0px 4px 4px;

		width: 85px;
		height: 90px;
	}
	.news-item:hover > span,
	.news-item:hover > div:last-child > span{
		color: #d6ab7e;
	}
	.news-time{	
		height: 100%;
		display: flex;
		color: #fff;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: 14px;
	}
	.news-time > span:first-child{
		font-size: 48px;
		line-height: 48px;
		margin-bottom: 8px;
	}
	.news{
		display: flex;
		flex-direction: row;
		width: 100%;
	}
	.news  ul li >div:last-child{
		width: 83%;
	}
	.news > div >span,
	.news  ul li > div:last-child > span{
		display: block;
		margin-top: 30px;
		font-size: 18px;
		letter-spacing: 1px;
		color: #333333;
		font-weight: 600;

		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.news > div > p ,
	.news  ul li > div:last-child > p{
		font-size: 15px;
		color: #666666;
		margin-top: 15px;
		display: -webkit-box;     
		-webkit-line-clamp: 2;     
		-webkit-box-orient: vertical;     
		overflow: hidden;
	}
	.news  ul{
		margin-left: 70px;
	}
	.news  ul li{
		display: flex;
		flex-direction: row;
		align-items: center;
		cursor: pointer;
	}
	.news  ul li .news-time{
		color: #999;
		margin-right: 35px;
	}
	.news  ul li > div:last-child > span{
		margin-top: 0;
	}
	.news  ul li{
		padding: 30px 0;
		box-sizing: border-box;
		border-bottom: 1px solid #eee;
	}
	.news  ul li:first-child{
		padding-top: 0;
	}
	.news  ul li:last-child{
		border: none;
	}
	.toNews{
		display: block;
		margin: 60px 0 120px 0;
		background-image: url("../imgs/index/a1.png");
		background-repeat: no-repeat;
		width: 210px;
		height: 50px;
	}
	.toNews:hover{
		background-image: url("../imgs/index/a2.png");
	}










	@media only screen and (max-width: 1199px) {
		.typeArea{
			width: 100vw;
			padding: 35px 20px 40px;
			box-sizing: border-box;
			overflow: hidden;
		}
		.content-Title{
			margin: 0;
		}
		.content-Title > span:first-child{
			font-size: 20px;
		}
		.content-Title > span:last-child{
			font-size: 7px;
			line-height: 7px;
			margin-top: 14px;
		}
		.content-text{
			margin-top: 28px;
		}
		.content-text>p{
			display: none;
		}
		.content-text>p:first-child{
			line-height: 28px;
			margin-top: 0;
			font-size: 14px;

			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 10;
			-webkit-box-orient: vertical;
			
		}
		.chanp{
			flex-direction: column;
			margin: 30px 0 15px 0;
		}
		.typeArea > .chanp > div{
			background-image: none;
			width: 100%;
			border-radius: 5px;
			margin-bottom: 25px;
			background-color: #f8f8f8;
			overflow: hidden;

			height: auto;
		}
		.typeArea > .chanp > div:last-child{
			margin: 0;
		}
		.index>.typeArea:nth-child(3){
			padding-bottom:20px;
		}
		.chanp>div>div{
			width: 100%;
			padding: 0;
			position: static;
		}
		.chanp>div>div>img{
			width: 100%;
			height: 176px;
		}
		.chanp span{
			margin-left: 15px;
			line-height: 37px;
		}
		.chanp p{
			padding: 0 15px;
			line-height: 26px;
			margin: 9px 0;
		}
		.history{
			background-image: none;
			height: auto !important;
			background-image: url('../imgs/index/historyBg.png');
			background-size: 100% 100%;
		}
		.history-img{
			background-image: none;
			overflow: hidden;
			height: 100%;
		}
		.typeArea .history-img > div{
			width: 100%;
			height: auto;
			margin-top: 26px;
			position: static;
		}
		.typeArea .history-img > div:nth-child(2){
			display: flex;
			justify-content: space-between;
			margin: 40px 0;
		}
		.typeArea .history-img img{
			width: 150px;
			height: 150px;
		}
		.typeArea .history-img span{
			width: auto;
			color: #fff;
			font-size: 14px;
		}
		.craft-content{
			overflow: auto;
		}
		.craft-content>div{
			width: 240vw;
			overflow: auto;
			flex: 1;
			padding-right:20px;
			margin: 36px 0 20px 0;
		}
		.craft-content::-webkit-scrollbar{
			display: none;
		}
		.craft-content>div>div{
			width: 286px;
			margin-left: 20px;
			box-sizing: border-box;
			height: auto;
		}
		.craft-content img{
			height: 175px;
		}
		.craft > .typeArea{
			padding-right: 0;
		}
		.craft-content>div>div>div{
			height: auto;
			background-color: #fff;
			border-radius: 4px;
			overflow: hidden;
		}
		.craft-content>div>div>div>div{
			background-color: #fff;
			border-radius: 0;
			color: #444;
			font-size: 14px;
			line-height: 26px;
			padding: 18px 17px;
			height: auto;
		}
		.news{
			width: 100%;
			display: flex;
			flex-direction: column;
		}
		.news > div{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			overflow: hidden;
		}
		.news-img{
			height: 176px !important;
			position: relative;
		}
		.news-img img{
			height: 100%;
		}
		.news-img > .img{
			margin: 0;
		}
		.news-img>div:last-child{
			position: absolute;
			top: 176px;
		}
		.news>div:first-child>span,
		.news>div:first-child>p{
			width: 78%;
			text-align: left;
			font-size: 16px;
		}
		.news-img > div{
			top: auto;
			left: 0;
			background-color: #fff;
			margin-top: 21px;
			width: 70px;
		}
		.news-time{
			color: #999;
			align-items: flex-start
		}
		.news > div >span{
			margin-top: 25px;
		}
		.news-item,
		.news ul li:last-child{
			border-bottom: 1px solid #eee;
			padding-bottom: 20px;
		}
		.news ul{
			margin-left: 0;
			width: 100%;
		}
		.news ul li .news-time{
			margin-right: 0;
		}
		.news ul li>div:last-child{
			padding-left: 20px;
			overflow:auto;
		}
		.toNews{
			margin: 30px 0 0;
			width: 125px;
			background-size: 125px;
		}
		.news ul li > div:last-child > span{
			font-size: 16px;
			width: 100%;
		}
		.news ul li > div:last-child > p{
			font-size: 14px;
		}
		.news > div > p{
			font-size: 14px !important;
		}
	}
</style>
